import React, { useEffect, useState } from 'react';
import './App.css';
import bg from "./cronkite.jpg"
import one from "./cronkiteGifs/1.gif"
import two from "./cronkiteGifs/2.gif"
import three from "./cronkiteGifs/3.gif"
import four from "./cronkiteGifs/4.gif"
import tv from "./istockphoto-628736088-612x612.png"
function App() {
  const [currentBg, setCurrentBg] = useState(one);
  const bgGifs = [one, two, three, four]; // Array of your GIF paths

  useEffect(() => {
    let index = 0;
    const interval = setInterval(() => {
      if (index === bgGifs.length) {
        index = 0;
      }
      setCurrentBg(bgGifs[index]);
      index++;
    }, 5000); // Change background every 5 seconds

    return () => clearInterval(interval);
  }, []);
  return (
    <div className="flex flex-col justify-center min-h-screen bg-slate-100 text-black relative w-full h-screen relative">
      <div className="w-full h-full bg-white absolute backdrop-filter backdrop-blur-xl bg-opacity-60 z-40"></div>
      <img src={currentBg} className="object-fit absolute w-full h-screen opacity-90" />
      <div className="absolute top-10 left-72 w-12 h-12 bg-gray-900">
      </div>
      <div className="absolute top-72 right-12 w-12 h-12 bg-green-400 opacity-10 z-50">
      </div>
      <div className="absolute top-[12vh] right-[32vw] w-12 h-12 bg-yellow-400 opacity-10 z-50">
      </div>
      <div className="absolute top-[62vh] right-[72vw] w-12 h-12 bg-blue-400 opacity-10 z-50">
      </div>
      <div className="absolute top-[45vh] right-[90vw] w-12 h-12 bg-pink-400 opacity-10 z-50">
      </div>
      <div className="absolute top-[70vh] right-[28vw] w-12 h-12 bg-red-400 opacity-10 z-50">
      </div>
      <div className="w-full h-screen flex flex-col bg-white">


        <div className="mx-auto my-auto w-1/2 bg-white backdrop-filter shadow-sm shadow-black backdrop-blur-lg bg-opacity-100 backdrop-filter backdrop-blur-xl pt-8 pb-4 px-12 text-center retro z-50 ">

          <p className="text-sm leading-loose tracking-wide my-12">
            <span className="font-bold font-serif text-3xl">An iconic broadcaster</span>  that brought together a nation for over a generation, brought back to life. We hope his soothing voice and mannerisms will bring comfort, joy, and that familiar feeling of the evening news back to the so many people who miss him dearly.
          </p>

          <p className="text-sm leading-loose tracking-wide">
            The AI <span className="italic">Walter Cronkite</span> News Hotline brings the broadcasting style of the iconic Walter Cronkite into the modern era through advanced AI...
          </p>

          <p className="text-sm leading-loose tracking-wide mt-6">
            Call below, and hear the magic.
          </p>
          <p className="text-sm leading-loose tracking-wide mt-2 italic light font-serif">
            "Thats the way it is"
          </p>


          <a href="tel:415-237-6063"
            className="inline-block mt-8 py-3 px-6 rounded-none shadow-sm shadow-black text-black retro-font text-2xl font-normal">
            415-237-6063
          </a>


        </div>
      </div>
      <div className="z-50 absolute bottom-2 w-full text-center italic text-xs text-opacity-40 text-white underline"> <a href="https://bland.ai">made with magic, love, & bland ai </a></div>
    </div>
  );

}

export default App;